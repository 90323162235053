import React, { useCallback, useRef, useState } from "react";
import AdminHeader from "../admin/AdminHeader";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import Box from "@mui/material/Box";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getstorelist } from "../API";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import Loader from "../components/Popups/Loader";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TotalOrdersChart from "../components/Charts/TotalOrdersChart";
import TotalSalesChart from "../components/Charts/TotalSalesChart";
import AverageCartChart from "../components/Charts/AverageCartChart";
import Calendar from "../components/Calendar/Calendar";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import * as XLSX from "xlsx";
import StoreUptime from "../components/Charts/StoreUptime";
import { HorizontalBar } from "../components/Charts/HorizontalBar";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function Reports(props) {
  let token = localStorage.getItem("token");

  const navigate = useNavigate();
  const [, setorderaccepted] = useState(false);
  // const[cancelorder,setcancelorder] =useState(false)
  const [orderlist, setorderlist] = useState();
  const [reportOrderList, setReportOrderList] = useState();
  const [refresh, setrefresh] = useState(false);
  const size = "10";
  const [page, setpage] = useState(1);
  const [showcount, setshowcount] = useState();
  const [Paginationlength, setPaginationlength] = useState(false);
  const [currentpage, setcurrentpage] = useState(1);
  const [storelistData, setStorelistData] = useState();
  const [reportsorderlist, setreportsorderlist] = useState();
  const [mostFrequentDropId, setmostFrequentDropId] = useState(null);
  const [bestseller, setbestseller] = useState();
  const [bestsellerno, setbestsellerno] = useState();
  const [loaderState, setloaderstate] = useState(false);
  //  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [, setOpen] = useState(false);
  const [currentStore, setcurrentStore] = useState();
  const [currentStoreType, setCurrentStoreType] = useState();
  const [currentStoreTimezone, setCurrentStoreTimezone] =
    useState("America/New_York");
  const { startDate, endDate } = useSelector(
    (state) => state.datefilterreducer
  );
  const columnsToExclude = [
    "storeId",
    "statusCode",
    "currency",
    "ottonomyHit",
    "language",
    "pin",
    "paymentRefrence",
    "message",
    "OrderData",
  ];

  const exportToExcel = (data, fileName, storeType, storeTimezone) => {
    // Create a new array to hold the rows for the Excel sheet
    const rows = [];

    if (storeType === "self") {
      data.forEach((order) => {
        if(order.status !== "order cancelled") {
          let order_date = new Date(order.createdAt).toLocaleString("en-US", {
            timeZone: storeTimezone,
          });
          const newRow = {
            OrderID: order.orderId,
            Username: order.userName,
            Status: order.status,
            "Mobile Number": order.mobileNumber,
            OrderData: "",
            "Pre-Tax Amount": `${order.currency} ${order.cartTotal}`,
            Tax: `${order.currency} ${order.tax}`,
            "Total Amount": `${order.currency} ${(
              parseFloat(order.cartTotal) + parseFloat(order.tax)
            ).toFixed(2)}`,
            "Payment Gateway Fixed Fees": `${order.currency} 0.3`,
            "Payment Gateway Variable Fees": `${order.currency} ${(
              (parseFloat(order.cartTotal) + parseFloat(order.tax)) *
              0.029
            ).toFixed(2)}`,
            Payout: `${order.currency} ${(
              parseFloat(order.cartTotal) -
              0.3 -
              (parseFloat(order.cartTotal) + parseFloat(order.tax)) * 0.029
            ).toFixed(2)}`,
            "Pickup Location": order.pickupId,
            "Delivery Location": order.dropId,
            "Delivery Time": order.deliveryTime,
            "Order Date": order_date.split(",")[0],
            "Order Time": order_date.split(",")[1] + " EST",
          };
  
          // Concatenate the name and quantity values from orderData array
  
          newRow.OrderData = order.orderData
            .map((item) => `${item.name} X${item.quantity}`)
            .join(", ");
  
          rows.push(newRow);
        }
      });
      // Calculate the sum of all totalAmount fields
      const totalPreTaxAmount = data.reduce(
        (sum, order) => sum + parseFloat(order.cartTotal),
        0
      );
      const totaltaxsum = data.reduce(
        (sum, order) => sum + parseFloat(order.tax),
        0
      );

      const totalAmountSum = data.reduce(
        (sum, order) =>
          sum + (parseFloat(order.cartTotal) + parseFloat(order.tax)),
        0
      );

      const paymentGatewayFixedFees = data.reduce((sum, order) => sum + 0.3, 0);

      const paymentGatewayVariableFees = data.reduce(
        (sum, order) =>
          sum + (parseFloat(order.cartTotal) + parseFloat(order.tax)) * 0.029,
        0
      );

      const totalPayout = data.reduce(
        (sum, order) =>
          sum +
          (parseFloat(order.cartTotal) -
            0.3 -
            (parseFloat(order.cartTotal) + parseFloat(order.tax)) * 0.029),
        0
      );
      // Add the "Sub Total" row
      const subTotalRow = {
        "Pre-Tax Amount": `${data[0]?.currency} ${totalPreTaxAmount.toFixed(
          2
        )}`,
        Tax: `${data[0]?.currency} ${totaltaxsum.toFixed(2)}`,
        "Total Amount": `${data[0]?.currency} ${totalAmountSum.toFixed(2)}`,
        "Payment Gateway Fixed Fees": `${
          data[0]?.currency
        } ${paymentGatewayFixedFees.toFixed(2)}`,
        "Payment Gateway Variable Fees": `${
          data[0]?.currency
        } ${paymentGatewayVariableFees.toFixed(2)}`,
        Payout: `${data[0]?.currency} ${totalPayout.toFixed(2)}`,
      };
      rows.push(subTotalRow);
    } else {
      data.forEach((order) => {
        if(order.status !== "order cancelled") {
          let order_date = new Date(order.createdAt).toLocaleString("en-US", {
            timeZone: storeTimezone,
          });
  
          const newRow = {
            OrderID: order.orderId,
            Username: order.userName,
            Status: order.status,
            "Mobile Number": order.mobileNumber,
            "Pickup Location": order.pickupId,
            "Delivery Location": order.dropId,
            "Delivery Time": order.deliveryTime,
            "Order Date": order_date.split(",")[0],
            "Order Time": order_date.split(",")[1] + " EST",
          };
          rows.push(newRow);
        }
      });
    }

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, fileName + ".xlsx");
  };

  const handleExportClick = () => {
    axios
      .post(
        `${process.env.REACT_APP_ORDERAT}/order/list`,
        {
          page: "0",
          pageSize: "0",
          storeId: currentStore,
          startDate: startDate,
          endDate: endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log("resornf", res);
        //  excelData.push(res.data?.order?.filter((item) => item.status !== "booked"))
        exportToExcel(
          res.data?.order?.filter((item) => item.status !== "booked"),
          `order_list - ${
            startDate
              ? new Date(startDate)

                  .toLocaleString("en-US", {
                    timeZone: currentStoreTimezone,
                  })
                  .split(",")[0]
              : new Date()
                  .toLocaleString("en-US", {
                    timeZone: currentStoreTimezone,
                  })
                  .split(",")[0]
          } - ${
            endDate
              ? new Date(endDate)
                  .toLocaleString("en-US", {
                    timeZone: currentStoreTimezone,
                  })
                  .split(",")[0]
              : new Date()
                  .toLocaleString("en-US", {
                    timeZone: currentStoreTimezone,
                  })
                  .split(",")[0]
          }`,
          currentStoreType,
          currentStoreTimezone
        );
      })
      .catch((err) => {});
  };

  const filterOrdersByDate = () => {
    // If either start or end date is not selected, return the original order list data.
    if (!startDate || !endDate) {
      return reportOrderList;
    }

    // Convert start and end dates to Date objects for comparison.
    const start = new Date(
      new Date(startDate).toLocaleString("en-US", {
        timeZone: currentStoreTimezone,
      })
    );
    const end = new Date(
      new Date(endDate).toLocaleString("en-US", {
        timeZone: currentStoreTimezone,
      })
    );
    // Filter the orders based on the date range.

    return reportOrderList?.filter((order) => {
      const orderDate = new Date(
        new Date(order.createdAt).toLocaleString("en-US", {
          timeZone: currentStoreTimezone,
        })
      );
      // new Date(order.createdAt);
      return orderDate >= start && orderDate <= end;
    });
  };

  const filteredOrdersByPage = () => {
    // If either start or end date is not selected, return the original order list data.
    if (!startDate || !endDate) {
      return orderlist;
    }

    // Convert start and end dates to Date objects for comparison.
    const start = new Date(
      new Date(startDate).toLocaleString("en-US", {
        timeZone: currentStoreTimezone,
      })
    );
    const end = new Date(
      new Date(endDate).toLocaleString("en-US", {
        timeZone: currentStoreTimezone,
      })
    );
    // Filter the orders based on the date range.
    // console.log('orderlistorderlist',orderlist)
    return orderlist?.filter((order) => {
      const orderDate = new Date(
        new Date(order.createdAt).toLocaleString("en-US", {
          timeZone: currentStoreTimezone,
        })
      );

      return orderDate >= start && orderDate <= end;
    });
  };

  const filteredOrders = filteredOrdersByPage();

  const filteredOrdersForReport = filterOrdersByDate();

  const theme = createTheme({
    components: {
      MuiPagination: {
        styleOverrides: {
          ul: {
            "& .Mui-selected": {
              backgroundColor: "#00B7D4 !important", // Your desired background color for the selected option
              color: "white", // Your desired text color for the selected option
              "&:hover": {
                backgroundColor: "#0099C0", // Your desired background color for the selected option on hover
              },
            },
          },
        },
      },
    },
  });
  const handleChangetwo = (event, value) => {
    setpage(value);
    setcurrentpage(value);
  };
  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined
    ) {
      navigate("/");
      console.log("token", token);
    }
  }, [navigate]);

  useEffect(() => {
    getstorelist()
      .then((res) => {
        // console.log('response storelist',res)
        if (res.data.status === 200 || res.data.status === "200") {
          setStorelistData(res.data.message);
          setcurrentStore(res.data?.message[0]?.storeId);
          setCurrentStoreType(res.data?.message[0]?.type);
          setCurrentStoreTimezone(res.data?.message[0]?.timezone);
        } else {
          setStorelistData([]);
        }
      })
      .catch((err) => {
        console.log("Error in Store list", err);
      });
  }, []);
  useEffect(() => {
    setorderaccepted(true);
    setrefresh(!refresh);
    const dropIdFrequency = {};
    // Count the occurrences of each dropId
    reportOrderList?.forEach((order) => {
      const dropId = order.dropId;
      if (dropIdFrequency[dropId]) {
        dropIdFrequency[dropId]++;
      } else {
        dropIdFrequency[dropId] = 1;
      }
    });

    // Find the dropId that occurs most frequently

    let maxFrequency = 0;
    for (const dropId in dropIdFrequency) {
      if (dropIdFrequency[dropId] > maxFrequency) {
        maxFrequency = dropIdFrequency[dropId];
        setmostFrequentDropId(dropId);
      }
    }
  }, [reportOrderList]);

  useEffect(() => {
    if (currentStore) {
      const orderDataFrequency = {};
      let maxFrequency = 0;
      // Count the occurrences of each orderData item
      reportOrderList?.forEach((order) => {
        order?.orderData?.forEach((item) => {
          const itemId = item.productId; // Using productId as a unique identifier for items
          if (orderDataFrequency[itemId]) {
            orderDataFrequency[itemId]++;
          } else {
            orderDataFrequency[itemId] = 1;
          }
        });
      });

      // Find the orderData item that appears most frequently
      let mostFrequentItem = null;

      for (const itemId in orderDataFrequency) {
        if (orderDataFrequency[itemId] > maxFrequency) {
          maxFrequency = orderDataFrequency[itemId];
          setbestsellerno(maxFrequency);
          mostFrequentItem = itemId;
        }
      }

      // Now you might want to find the corresponding item's name from the orders
      reportOrderList?.forEach((order) => {
        const foundItem = order.orderData.find(
          (item) => item.productId === mostFrequentItem
        );
        if (foundItem) {
          // console.log(`Its name is: ${foundItem.name}`);
          setbestseller(foundItem?.name);
        }
      });
    }
    // console.log(`Frequency: ${maxFrequency}`);
  }, [reportOrderList, currentStore]);

  const updateState = useCallback(async () => {
    return axios
      .post(
        `${process.env.REACT_APP_ORDERAT}/order/list`,
        {
          storeId: currentStore,
          page: page,
          pageSize: size,
          startDate: startDate,
          endDate: endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // setTotalOrders(res.data.data.count)
        // setorderlist(res.data.data.order)
        setorderlist(
          res.data.order?.filter((item) => item.status !== "booked")
        );
        // console.log('res.data.order',res)
        if (res.data?.count > size) {
          setPaginationlength(true);
          // setPaginationlength(Paginationlength => Paginationlength+1)
        }
        if (res.data.count % 1 === 0) {
          setshowcount(Math.floor(res.data.count / 10) + 1);
          // console.log('res.data.data.count%1',res.data.data.count%1)
          // console.log('showcount',showcount)
        } else {
          setshowcount(res.data.order?.length / 10);
          setPaginationlength(false);
        }
      })
      .catch((err) => {
        console.log("Error in Order list", err);
      });
  }, [currentpage, size, currentStore, startDate, endDate]);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_ORDERAT}/order/list`,
        {
          storeId: currentStore,
          page: "0",
          pageSize: "0",
          startDate: startDate,
          endDate: endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // setTotalOrders(res.data.data.count)
        // setorderlist(res.data.data.order)
        setReportOrderList(
          res.data.order?.filter((item) => item.status !== "booked")
        );
      })
      .catch((err) => {
        console.log("Error in Order list", err);
      });
  }, [startDate, endDate, currentStore]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 5000);

  const getStatusclass = (orderStatus) => {
    switch (orderStatus) {
      case "InProgress":
        return "p_inprogress";
      case "booked":
        return "";
      case "order delivered":
        return "p_delivered";
      case "order accepted":
        return "p_delivered";
      case "order cancelled":
        return "p_cancelled";
      case "New":
        return "p_new";
      case "order confirmed":
        return "p_new";
      default:
        return "p_inprogress";
    }
  };

  const gettotalcustomers = () => {
    const uniqueMobileNumbers = [];
    // Filter out duplicate objects based on mobileNumber
    const uniqueOrders = filteredOrdersForReport?.filter((order) => {
      if (!uniqueMobileNumbers.includes(order.mobileNumber)) {
        uniqueMobileNumbers.push(order.mobileNumber);
        return true;
      }
      return false;
    });

    const numberOfUniqueOrders = uniqueOrders?.length;
    if (numberOfUniqueOrders) {
      return <h5> {numberOfUniqueOrders} </h5>;
    } else {
      return "No Data to show";
    }
  };

  return (
    <>
      <div className="DesktopMain-wrapper">
        {loaderState ? (
          <>
            {" "}
            <Loader handleClose={handleClose} />
          </>
        ) : null}
        {/* {console.log('filteredOrders',filteredOrders)}   */}
        <AdminHeader reports={true} storelistData={storelistData} />
        <div className="container padding-top">
          <div className="row">
            <div className="" style={{ textAlign: "left" }}>
              <div className="AdminDashboard-tabs-left-side">
                <>
                  <div className="AdminDashboard-tabs-left-side_heading">
                    <h1>
                      {" "}
                      Reports <ChevronRightOutlinedIcon />
                      {storelistData?.length > 1 ? (
                        <span>
                          <Dropdown
                            onSelect={(eventKey) => {
                              const selectedItem = storelistData.find(
                                (item) => item.storeId === eventKey
                              );
                              setcurrentStore(selectedItem.storeId);
                              setCurrentStoreType(selectedItem.type);
                              console.log(selectedItem, "SELECTED");
                              setCurrentStoreTimezone(selectedItem.timezone);
                            }}
                            id="Store_dropdown_select"
                          >
                            <Dropdown.Toggle id="dropdown-basic">
                              {currentStore}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="custom-dropdown-menu">
                              {storelistData?.map((item) => (
                                <Dropdown.Item
                                  key={item.storeId}
                                  eventKey={item.storeId}
                                >
                                  {item.storeId}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </span>
                      ) : (
                        <span> {currentStore}</span>
                      )}
                    </h1>
                    <Calendar currentStoreTimezone={currentStoreTimezone} />
                  </div>
                </>
              </div>
            </div>
          </div>
          <div className="row">
            {currentStoreType === "self" && (
              <>
                <div className="col-md-4">
                  <div className="Reports_page_graph_div">
                    {filteredOrdersForReport !== undefined ? (
                      <>
                        <TotalSalesChart
                          orderlist={filteredOrdersForReport}
                          currentStore={currentStore}
                          currentStoreTimezone={currentStoreTimezone}
                        />
                      </>
                    ) : (
                      <div>
                        <h5> Total Sales</h5>
                        <p> No Data to show </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="Reports_page_graph_div">
                    {filteredOrdersForReport !== undefined ? (
                      <AverageCartChart
                        orderlist={filteredOrdersForReport}
                        currentStore={currentStore}
                        currentStoreTimezone={currentStoreTimezone}
                      />
                    ) : (
                      <div>
                        <h5>Average Cart Values </h5>
                        <p> No Data to show </p>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="col-md-4">
              <div className="Reports_page_graph_div">
                {filteredOrdersForReport !== undefined ? (
                  <TotalOrdersChart
                    orderlist={filteredOrdersForReport}
                    currentStore={currentStore}
                    currentStoreTimezone={currentStoreTimezone}
                  />
                ) : (
                  <div>
                    <h5> Total Orders</h5>
                    <p> No Data to show </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row OrderData_div">
            <div className="col-md-12">
              <div className="row">
                {/* {currentStoreType === "self" && ( */}
                <div className="col-md-6">
                  <div className="reports_page_content_div reports_page_content_div_first">
                    <img src="/assets/images/customers.svg" alt="" />
                    <p> Total Customer </p>
                    <> {gettotalcustomers()} </>
                  </div>
                </div>
                {/* )} */}
                <div className="col-md-6">
                  <div className="reports_page_content_div reports_page_content_div_first">
                    <img src="/assets/images/orders.svg" alt="" />
                    <p> Total Orders</p>
                    {reportOrderList ? (
                      <>
                        <div className="d_flex_center">
                          <div>
                            <h5>
                              {
                                reportOrderList?.filter(
                                  (item) => item.status === "order delivered"
                                ).length
                              }{" "}
                              <span> Delivered</span>
                            </h5>
                          </div>
                          <div className="reports_page_content_canceldiv">
                            <h5>
                              {
                                reportOrderList?.filter(
                                  (item) => item.status === "order cancelled"
                                ).length
                              }{" "}
                              <span> Cancelled</span>
                            </h5>
                          </div>
                        </div>
                      </>
                    ) : (
                      <> No Data to show</>
                    )}
                  </div>
                </div>
                {currentStoreType === "self" && (
                  <div className="col-md-6">
                    <div className="reports_page_content_div reports_page_content_div_first">
                      <img src="/assets/images/products.svg" alt="" />
                      <p> Best Seller </p>
                      {reportOrderList ? (
                        <>
                          <h5> {bestseller}</h5>
                        </>
                      ) : (
                        <>No data to show</>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="reports_page_content_div reports_page_content_div_first">
                    <img src="/assets/images/location.svg" alt="" />
                    <p> Most Delivered Location</p>
                    {reportOrderList ? (
                      <>
                        <h5> {mostFrequentDropId}</h5>
                      </>
                    ) : (
                      <>No Data to show</>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <StoreUptime
                    currentStore={currentStore}
                    currentStoreTimezone={currentStoreTimezone}
                  />

                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="row OrderData_div"> 
             <div className="col-md-3"> 
                <div className="reports_page_content_div reports_page_content_div_first">
                   <p> Total Customer </p>  
                  <h5> {gettotalcustomers()} </h5>
               </div>
             </div>
             <div className="col-md-3">
             <div className="reports_page_content_div reports_page_content_div_first">
                 <p> Total Orders</p> 
                 {reportOrderList ? <>
                  <div className="d_flex_center">   
                   <div> 
                   <h5>{reportOrderList?.filter(item=> item.status ==='order delivered').length} <span> Delivered</span></h5>
                   
                </div>
                <div className="reports_page_content_canceldiv"> 
                    <h5>{reportOrderList?.filter(item=> item.status ==='order cancelled').length} <span> Cancelled</span></h5>
                   
                </div>
               </div>  
                 </>
                 :<> No Data to show</>}
                
             </div>
             
              </div>
         
             <div className="col-md-3"> 
                <div className="reports_page_content_div reports_page_content_div_first">
                      <p> Best Seller </p>
                       {reportOrderList ?<>
                      <h5>  {bestseller}</h5>
                      </>:<>No data to show</>}
                </div>
             </div>
             <div className="col-md-3"> 
                <div className="reports_page_content_div reports_page_content_div_first">
                    <p> Most Delivered Location</p>
                    {reportOrderList ? <>
                      <h5> {mostFrequentDropId}</h5>
                    </>:<>No Data to show</> }
                </div>
             </div>
          </div> */}
          <div className="row reports_page_orderlist_table">
            <div className="col-md-12">
              <div className="Zone_page_content_wrapper_main_div">
                {/* {console.log('filteredOrdersForReport',filteredOrdersForReport)} */}
                <div className="Zone_page_filter_div_wrapper">
                  <h3> Orders List</h3>
                  {filteredOrders?.length > 0 && (
                    <button
                      onClick={handleExportClick}
                      className="Modal-button-Main-btn_yes"
                    >
                      {" "}
                      <img
                        className="Export_excel_image "
                        src="/assets/images/Export_excel.svg"
                        alt=""
                      />
                      Export Excel
                    </button>
                  )}
                </div>
              </div>
              <div className="Stores_Page_store_list_table">
                {/* {console.log('orderlist',orderlist)} */}

                {filteredOrders?.length > 0 ? (
                  <>
                    <table
                      className="Store_page_table"
                      cellPadding="0"
                      cellSpacing="0"
                    >
                      <thead>
                        <tr>
                          <th> OrderId</th>
                          <th> Status </th>
                          <th> Delivery Gate</th>
                          {currentStoreType === "self" && <th> Order Info</th>}

                          {/* <th> Reason</th> */}
                          <th> Time Stamp</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredOrders?.map((item, index) => {
                          if (item.storeId === currentStore) {
                            return (
                              <tr key={index}>
                                <td style={{ cursor: "pointer" }}>
                                  {" "}
                                  {item.orderId}
                                </td>
                                <td style={{ cursor: "pointer" }}>
                                  <Tooltip
                                    placement="top-end"
                                    title={`${
                                      item.status === "order cancelled"
                                        ? `Cancellation Reason : ${item.message}`
                                        : ""
                                    }`}
                                  >
                                    <p className={getStatusclass(item.status)}>
                                      {" "}
                                      {item.status}
                                    </p>
                                  </Tooltip>
                                </td>
                                <td> {item.dropId} </td>
                                {currentStoreType === "self" && (
                                  <td style={{ color: "#3CB0CC" }}>
                                    {" "}
                                    {item?.orderData?.map((itm, index) => {
                                      return (
                                        <p
                                          key={"OrderInfo" + index}
                                          className="OrderInfo_p"
                                        >
                                          {" "}
                                          {itm.name} X{" "}
                                          <span>{itm.quantity}</span>,
                                        </p>
                                      );
                                    })}{" "}
                                  </td>
                                )}

                                {/* <td style={{ textAlign: "center" }}>
                                  {" "}
                                  {item.reason}{" "}
                                </td> */}
                                <td>
                                  {new Date(item.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                      timeZone: currentStoreTimezone,
                                    }
                                  ) + " EST"}
                                  {/* {new Date(item.createdAt)
                                    .toString()
                                    .replace(
                                      "GMT+0530 (India Standard Time)",
                                      ""
                                    )} */}
                                </td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </tbody>
                    </table>
                    <div>
                      {Paginationlength && filteredOrders.length > 0 ? (
                        <ThemeProvider theme={theme}>
                          <Pagination
                            className="PaginationWrapper_two"
                            count={showcount}
                            page={page}
                            onChange={handleChangetwo}
                            sx={{ width: "100%" }}
                          />
                        </ThemeProvider>
                      ) : null}
                    </div>
                  </>
                ) : (
                  <p> No Orders available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reports;
